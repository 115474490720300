<template>
  <div class="order_sub_bar flex-r-sb-c">
    <div class="left_btn flex-r-s-c">
      <!-- <div class="order_money_item flex-r-c-c">
        <div style="width:1rem">合计：</div>
        <div style="color:#ED0D0D;">￥</div>
        <div style="color:#ED0D0D;font-size:0.48rem">{{ retotal }}</div>
      </div> -->
    </div>
    <div class="right_btn flex-r-s-s">
      <van-button v-if="info.handOverFlag === 1" round block plain type="danger" @click="action('del')">取消订单
      </van-button>
      <!-- <van-button v-if="info.updateAddress" round block plain type="info" style="margin-left:0.5rem" color="#0767ab"
                  @click="action('address')"
      >{{ ischangeAddress?'确认修改':'修改地址' }}</van-button> -->
      <van-button v-if="info.toPay" round block type="info" color="#0767ab" style="margin-left:0.5rem"
                  @click="action('pay')"
      >重新支付
      </van-button>
    </div>
    <div class="right_btn flex-r-s-s">
      <van-button v-if="info.handOverFlag === 2 || info.handOverFlag === 3 || info.handOverFlag === 4 || info.handOverFlag === 5"
                  round block type="info" color="#0767ab" style="margin-right:0.5rem" @click="action('new')"
      >再来一单
      </van-button>
      <!-- <van-button v-if="info.logistics" round block plain type="info" style="margin-right:0.5rem" color="#0767ab" @click="action('express')">查看物流
      </van-button> -->
      <van-button v-if="info.updateComplete" round block plain type="info" color="#0767ab" @click="action('complete')">
        确认收货
      </van-button>
      <van-button v-if="info.applyForInvoice" round block plain type="info" color="#0767ab" style="margin-right:0.5rem"
                  @click="action('applyInvoice')"
      >申请开票
      </van-button>
      <van-button v-if="info.checkInvoice" round block plain type="info" color="#0767ab" style="margin-right:0.5rem"
                  @click="action('seeInvoice')"
      >
        查看发票
      </van-button>
      <van-button v-if="info.toRefund" round block plain type="info" color="#0767ab" style="margin-right:0.5rem"
                  @click="action('return')"
      >我要退款</van-button>

      <!-- <van-button v-if="info.handOverFlag === 6" round block type="info" color="#0767ab" style="margin-left:0.5rem"
                  @click="action('cancerReturn')"
      >取消退款</van-button> -->
      <!-- <van-button v-if="info.aftermarket" round block plain type="info" color="#0767ab" style="margin-left:0.5rem"
                  @click="action('returnProcess')"
      >退款进度</van-button> -->
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['info'],
  data() {
    return {
      ischangeAddress: false
    }
  },
  methods: {
    // 底部行为
    action(type) {
      if (type === 'address') { this.ischangeAddress = !this.ischangeAddress }
      this.$emit('action', type)
    }
  }
}
</script>

<style lang="scss">
.order_sub_bar {
  border-top: 1px solid rgb(214, 214, 214);
  position: fixed;
  width: 10rem;
  height: 1.71rem;
  background: #ffffff;
  opacity: 1;
  border-radius: 0rem;
  bottom: 0;
  .left_btn {
    width: 30%;
    padding-left: 0.3rem;
    .left_btn_item {
      font-size: 0.52rem;
      color: #686868;
    }
    .order_money_item {
      margin-left: 0.3rem;
      div {
        font-size: 0.32rem;
        line-height: 0.81rem;
        color: #333333;
      }
    }
    span {
      font-size: 0.32rem;
      font-weight: 400;
      color: #686868;
    }
  }
  .right_btn {
    font-size: 0.37rem;
    line-height: 1.17rem;
    color: #ffffff;
    padding-right: 0.3rem;
    .van-button {
      width: 2.57rem;
      height: 1.03rem;
    }
  }
}
</style>
